'use client'

import { IMetadataAvatar, MarketplacePlacement } from 'features/discover/type'
import { PostList } from 'features/feed/components/PostList'
import useQueryFeedAvatars from 'features/feed/hooks/useQueryFeedAvatars'
import { useEffect, useState } from 'react'
import { useSuggestAvatarsStore } from 'store/suggest-avatars-sotre'

export default function FeedPage() {
  const [data, setData] = useState<IMetadataAvatar[]>([])
  const { handleGetFeedAvatars, isEnd, loading } = useQueryFeedAvatars()

  const handleOnLoad = async () => {
    const res = await handleGetFeedAvatars(12)
    if (!res) return
    setData(pre => [...pre, ...res])
  }

  const handleGetSuggestAvatars = async () => {
    useSuggestAvatarsStore.setState({ loading: true })
    const res = await handleGetFeedAvatars(12)
    useSuggestAvatarsStore.setState({ avatars: res || [], loading: false })
  }

  useEffect(() => {
    handleGetSuggestAvatars()
    handleOnLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="scrollbar-none min768:pt-7 h-dvh overflow-scroll">
      <PostList
        isEnd={isEnd}
        loading={loading}
        posts={data}
        handleOnLoad={handleOnLoad}
        placement={MarketplacePlacement.feed_web}
      />
    </div>
  )
}

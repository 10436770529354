import { createSelectors } from '@paladise/utils/zustand-selector'
import { MarketplacePlacement } from 'features/discover/type'
import { createWithEqualityFn as create } from 'zustand/traditional'
import { subscribeWithSelector } from 'zustand/middleware'

export type MarketPlaceState = {
  isRefresh: Record<keyof typeof MarketplacePlacement, boolean>
}

export type MarketPlaceStore = MarketPlaceState

export const useMarketPlaceStoreBase = create<MarketPlaceStore>()(
  subscribeWithSelector((set, get) => ({
    isRefresh: {
      [MarketplacePlacement.feed_web]: true,
      [MarketplacePlacement.entertainment_web]: true,
      [MarketplacePlacement.for_you_banner_web]: true,
      [MarketplacePlacement.for_you_favorite_web]: true,
      [MarketplacePlacement.for_you_recommend_web]: true,
      [MarketplacePlacement.news_web]: true,
      [MarketplacePlacement.sports_web]: true,
      [MarketplacePlacement.trending_web]: true,
    },
  })),
)

export const useMarketPlaceStore = createSelectors(useMarketPlaceStoreBase)

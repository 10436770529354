import { useState } from 'react'
import { useMarketPlaceStore } from 'store/market-place-store'
import { getRecommendAvatars } from 'api/avatar'
import { clientFetch } from 'lib/fetch/client'
import { IMetadataAvatar } from 'features/discover/type'
import { defaultAvatarsSize } from 'features/discover/constant'

function useQueryFeedAvatars() {
  const [avatars, setAvatars] = useState<IMetadataAvatar[]>([])
  const [loading, setLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(false)

  const isRefresh = useMarketPlaceStore.use.isRefresh()

  const handleGetFeedAvatars = async (size?: number) => {
    try {
      setLoading(true)
      const page_size = size || defaultAvatarsSize
      const data = await getRecommendAvatars(
        clientFetch,
        isRefresh.feed_web,
        page_size,
      )

      useMarketPlaceStore.setState({
        isRefresh: { ...isRefresh, feed_web: false },
      })

      if (!data) {
        setIsEnd(true)
        return
      }
      setAvatars(data || [])
      setIsEnd(data.length === 0)
      return data
    } catch (error) {
      setIsEnd(true)
      return null
    } finally {
      setLoading(false)
    }
  }

  return {
    handleGetFeedAvatars,
    avatars,
    isEnd,
    loading,
  }
}

export default useQueryFeedAvatars
